button,a {
  &.primary {
    @apply h-12 px-4 gap-1 flex flex-row justify-center items-center rounded-button text-hrp-base bg-hrp-primary-500 active:bg-hrp-primary-700 hover:bg-hrp-primary-700 focus-visible:ring-4 focus-visible:ring-hrp-primary-900 outline-none;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 142.857%;
    &.small {
      @apply h-8;
    }

    &.xs{
      @apply h-6 px-2 hrp-text-body-xs-bold gap-1;
    }
    &.accent {
      @apply bg-hrp-accent-300 active:bg-hrp-accent-500 hover:bg-hrp-accent-500 text-hrp-accent-900 hover:text-hrp-base active:text-hrp-base;
    }
  }

  &.secondary {
    @apply h-12 px-4 gap-1 flex flex-row justify-center items-center rounded-button text-hrp-primary-500 bg-hrp-base  hover:bg-hrp-primary-300 hover:text-hrp-primary-900 focus-visible:ring-4 focus-visible:ring-hrp-primary-900 outline-none border-medium border-hrp-subtle-400 hover:border-hrp-primary-900;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 142.857%;
    &.active {
      @apply bg-hrp-primary-300 text-hrp-primary-900 border-hrp-primary-900;
    }
    &.small {
      @apply h-8;
    }
    &.xs{
      @apply h-6 px-2 hrp-text-body-xs-bold gap-1;
    }
    &.left {
      @apply rounded-r-none;
    }
    &.right {
      @apply rounded-l-none;
    }
    &.center {
      @apply rounded-none;
    }
    &.hrp-rounded {
      @apply rounded-full;
    }
  }
  &.tertiary {
    @apply h-12 px-4 gap-1 flex flex-row justify-center items-center rounded-button text-hrp-primary-700 bg-transparent active:bg-hrp-primary-300 hover:bg-hrp-primary-300 hover:text-hrp-primary-900 active:text-hrp-primary-900 focus-visible:ring-4 focus-visible:ring-hrp-primary-900 outline-none;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 142.857%;
    &.small {
      @apply h-8;
    }
    &.xs{
      @apply h-6 px-2 hrp-text-body-xs-bold gap-1;
    }
    &.active {
      @apply bg-hrp-primary-300 text-hrp-primary-900;
    }
    &.warning {
      @apply text-hrp-warning-900 hover:bg-hrp-warning-300
    }
  }

  &:disabled {
    @apply bg-hrp-disabled text-hrp-disabled-content border-hrp-disabled-content cursor-not-allowed;
  }
}
