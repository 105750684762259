@keyframes slideOutDown {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(100%);
    }
  }
  
.par-modal-close-animation {
    animation: slideOutDown 0.3s ease forwards;
}