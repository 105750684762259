// Text

// Button

.hrp-text-button {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 142.857%;
}

// Regular

.hrp-text-h24 {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.12px;
}

.hrp-text-body-20 {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.hrp-text-body-18 {
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.hrp-text-body {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.hrp-text-body-small {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
}

.hrp-text-body-xs {
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 133.333%;
}

//Emphasized

.hrp-text-h48-emph {
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  letter-spacing: -0.96px;
}

.hrp-text-h36-emph {
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 46.8px */
  letter-spacing: -0.36px;
}

.hrp-text-h30-emph {
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 39px */
  letter-spacing: -0.15px;
}

.hrp-text-h24-emph {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.12px;
}

.hrp-text-body-20-emph {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.hrp-text-body-18-emph {
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.hrp-text-body-emph {
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.hrp-text-body-small-emph {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 142.857%;
}

.hrp-text-body-xs-emph {
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 133.333%;
}

// Bold

.hrp-text-h36-bold {
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 46.8px */
  letter-spacing: -0.36px;
}

.hrp-text-h30-bold {
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 39px */
  letter-spacing: -0.15px;
}

.hrp-text-h24-bold {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.12px;
}

.hrp-text-body-20-bold {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.hrp-text-body-18-bold {
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.hrp-text-body-bold {
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.hrp-text-body-small-bold {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 142.857%;
}

.hrp-text-body-xs-bold {
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 133.333%;
}
