@import "@angular/cdk/overlay-prebuilt.css";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  @import "styles-text";
  @import "styles-buttons";
  @import "styles-animations";

  // Icons

  .icon-semibold {
    font-variation-settings: "wght" 600;
  }

  .icon-bold {
    font-variation-settings: "wght" 700;
  }
}

body {
  @apply fixed overflow-hidden w-full h-[100svh] top-0 left-0;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}

// Progress Spinner

.par-progress-spinner .par-progress-spinner-progress {
  @apply stroke-primary-200;
}

// Snackbar

par-snackbar {
  &.par-snackbar-success {
    @apply bg-success-content text-base font-bold;
  }
  &.par-snackbar-error {
    @apply bg-warning-content text-base font-bold;
  }
}

// Footer

.hrp-footer {
  @apply w-full overflow-hidden bottom-0 left-0 right-0 z-[500] bg-white rounded-t-md shadow-overlay;
}

// Temporary, fix par-select
par-select {
  //@apply w-full font-bold;
  background-color: var(--par-base) !important;
}

// Temporary, fix modal width Problem
.cdk-overlay-pane {
  @apply grid;
}

.par-modal {
  @apply bg-base text-base-content rounded-[5px];
  box-shadow: 2px 9px 12px #aeb7c1, 0px 0px 12px #aeb7c1;
  page-day-working-summary-per-day {
    @apply mobile:block tablet:hidden desktop:hidden;
  }

  .par-swipe-close-indicator {
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.5rem;
    margin-bottom: -1rem;
    background-color: var(--hrp-subtle-400);
    border-radius: 45px;
    padding: 0;
    border: 0;
    height: 4px;
    width: 48px;
  }
}

.par-modal-basic {
  @apply bg-base text-base-content rounded-[5px];
  box-shadow: 2px 9px 12px #aeb7c1, 0px 0px 12px #aeb7c1;
}

.iconActive {
  @apply text-primary-200;
}

.active {
  @apply bg-hrp-primary-300 text-hrp-primary-900 border-hrp-primary-900;
}
.skill-badge {
  @apply flex items-center rounded-full bg-subtle-50 border-medium border-primary-200 px-4 h-8 text-primary-200;
}

.tooltip {
  @apply bg-base-content flex flex-col justify-center rounded-button px-4 py-3 mt-2 text-hrp-base;
  @extend .hrp-text-body-xs;
}
.tooltip-sm {
  @apply p-2 px-3;
}
/* width */
::-webkit-scrollbar {
  width: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--hrp-subtle-700);
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--hrp-subtle-800);
}

.scroll-container {
  overflow: auto;
}

@keyframes load {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}
